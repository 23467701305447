import {Controller} from '../Controller';
import {BehaviorSubject, combineLatest, from, map, Observable, switchMap} from 'rxjs';
import {DataSource} from '../../common/decorators/rxdb/DataSource';
import {RxGroup} from '../../common/model/db/types/RxGroup';
import {Group, PartialGroup} from '../../core/rest-client/aat';
import {asString} from '../../common/helpers/converters/asString';
import {DataSourceKind} from '../../common/decorators/rxdb/DataSourceKind';
import {Throttled} from '../../common/decorators/methods/Throttled';
import {tAsString} from '../../common/helpers/react/text/tAsString';
import {ProductState} from '../../common/model/types/products/ProductState';
import {RxGroupAgent} from '../../common/model/db/types/RxGroupAgent';
import {Bound} from '../../common/decorators/methods/Bound';
import {PropertyState} from '../../common/model/types/properties/PropertyState';
import {AssigneesSearchResult} from '../../common/model/types/assignees/AssigneesSearchResult';
import {AssigneesSearchQuery} from '../../common/model/types/assignees/AssigneesSearchQuery';
import {Assignee} from '../../common/model/types/assignees/Assignee';
import {Cached} from '../../common/decorators/methods/Cached';
import {AddRemoveInfo} from '../../clients/react-app/app/content/groups/wizard/agents-step/AddRemoveInfo';
import {RxAgent} from '../../common/model/db/types/RxAgent';
import {RxPcc} from '../../common/model/db/types/RxPcc';
import {RxAgency} from '../../common/model/db/types/RxAgency';
import {AssigneesSearchResultLevel} from '../../common/model/types/assignees/AssigneesSearchResultLevel';
import {RxGroupPcc} from '../../common/model/db/types/RxGroupPcc';
import {RxGroupAgency} from '../../common/model/db/types/RxGroupAgency';
import {getAssigneeId} from '../../common/model/types/assignees/getAssigneeId';
import {distinctLikeABoss} from '../../common/helpers/rxjs/distinctLikeABoss';
import {ConfigurationSearchResult} from '../../common/model/types/configuration/ConfigurationSearchResult';
import {RxProductAgent} from "../../common/model/db/types/RxProductAgent";
import {RxProductPcc} from "../../common/model/db/types/RxProductPcc";
import {RxProductAgency} from "../../common/model/db/types/RxProductAgency";
import {asInt} from "../../common/helpers/converters/asInt";
import {isUndefined} from "../../common/types/guards/isUndefined";

export class GroupsController extends Controller {
    #assigneesSearchResults = new BehaviorSubject<AssigneesSearchResult | undefined>(undefined);
    #configurationSearchResults = new BehaviorSubject<ConfigurationSearchResult | undefined>(undefined);

    getAssigneesSearchResults$(): Observable<AssigneesSearchResult | undefined> {
        return this.#assigneesSearchResults;
    }

    getConfigurationSearchResults$(): Observable<ConfigurationSearchResult | undefined> {
        return this.#configurationSearchResults;
    }

    @Cached()
    getAssigneeIds$(groupId: number, ids: AddRemoveInfo = {
        add: [],
        remove: []
    }): Observable<string[]> {
        return combineLatest([
            this.getGroupAgencies$(groupId),
            this.getGroupAgents$(groupId),
            this.getGroupPccs$(groupId)
        ]).pipe(
            map(([agencies, agents, pccs]) => [
                ...agencies.map(it => it.agency),
                ...agents.map(it => it.agent),
                ...pccs.map(it => it.pcc),
                ...ids.add
            ].filter(it => !ids.remove.includes(it)).uniq()),
            distinctLikeABoss()
        );
    }

    async getAssigneeIds(groupId: number, ids: AddRemoveInfo = {
        add: [],
        remove: []
    }): Promise<string[]> {
        return this.getAssigneeIds$(groupId, ids);
    }

    @Cached()
    getAssigneesForAddAssigneeTable$(groupId: number, ids: AddRemoveInfo = {
        add: [],
        remove: []
    }): Observable<Assignee[] | undefined> {
        return combineLatest([
            this.getAssigneesSearchResults$(),
            this.getAssigneeIds$(groupId, ids)
        ]).pipe(
            switchMap(async ([it, currentIds]) => {
                if (it?.data && it?.breadcrumbs?.length) {
                    const lastBreadcrumb = it.breadcrumbs.last()!;
                    const id = asString(lastBreadcrumb?.id);

                    switch (lastBreadcrumb.level) {
                        case AssigneesSearchResultLevel.INTERNATIONAL:
                            return this.db.agencies.active_
                                .filter(it => {
                                    return !currentIds.includes(it.id) && it.parent_agency === id;
                                })
                                .map(it => {
                                    return {
                                        agency: it
                                    };
                                })
                                .sort((a: Assignee, b: Assignee): number => {
                                    if (a.agency?.name === b.agency?.name) return 0;
                                    if (isUndefined(a.agency) || isUndefined(b.agency) || isUndefined(a.agency?.name)
                                        || isUndefined(b.agency?.name)) return 0;
                                    return a.agency?.name > b.agency?.name ? 1 : -1;
                                });
                        case AssigneesSearchResultLevel.DOMESTIC:
                            return this.db.pccs.active_
                                .filter(it => {
                                    return !currentIds.includes(it.id) && it.agency === id;
                                })
                                .map(it => {
                                    return {
                                        pcc: it
                                    };
                                })
                                .sort((a: Assignee, b: Assignee): number => {
                                    if (a.pcc?.code === b.pcc?.code) return 0;
                                    if (isUndefined(a.pcc) || isUndefined(b.pcc) || isUndefined(a.pcc?.code)
                                        || isUndefined(b.pcc?.code)) return 0;
                                    return a.pcc?.code > b.pcc?.code ? 1 : -1;
                                });
                        case AssigneesSearchResultLevel.PCC:
                            return this.db.agents.active_
                                .filter(it => {
                                    return !currentIds.includes(it.id) && it.pcc === id;
                                })
                                .map(it => {
                                    return {
                                        agent: it
                                    };
                                })
                                .sort((a: Assignee, b: Assignee): number => {
                                    if (a.agent?.sabre_id === b.agent?.sabre_id) return 0;
                                    if (isUndefined(a.agent) || isUndefined(b.agent) || isUndefined(a.agent.sabre_id)
                                        || isUndefined(b.agent.sabre_id)) return 0;
                                    return a.agent?.sabre_id > b.agent?.sabre_id ? 1 : -1;
                                });
                    }
                }

                return it?.data
                    ?.filter(it => !currentIds.includes(getAssigneeId(it)));
            })
        );
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    getAssigneesForAssigneesStepTable$(groupId: number, ids: AddRemoveInfo = {
        add: [],
        remove: []
    }): Observable<Assignee[]> {
        return combineLatest([
            this.getAssigneeIds$(groupId, ids),
            this.getAgencies$(),
            this.getAgents$(),
            this.getPccs$()
        ]).pipe(
            map(([ids, agencies, agents, pccs]) => [
                ...agencies
                    .filter(it => ids.includes(it.id))
                    .map(it => {
                        return {
                            agency: it
                        };
                    })
                    .sort((a: Assignee, b: Assignee): number => {
                        if (a.agency?.name === b.agency?.name) return 0;
                        if (isUndefined(a.agency) || isUndefined(b.agency) || isUndefined(a.agency?.name)
                            || isUndefined(b.agency?.name)) return 0;
                        return a.agency?.name > b.agency?.name ? 1 : -1;
                    }),
                ...pccs
                    .filter(it => ids.includes(it.id))
                    .map(it => {
                        return {
                            pcc: it
                        };
                    })
                    .sort((a: Assignee, b: Assignee): number => {
                        if (a.pcc?.code === b.pcc?.code) return 0;
                        if (isUndefined(a.pcc) || isUndefined(b.pcc) || isUndefined(a.pcc?.code)
                            || isUndefined(b.pcc?.code)) return 0;
                        return a.pcc?.code > b.pcc?.code ? 1 : -1;
                    }),
                ...agents
                    .filter(it => ids.includes(it.id))
                    .map(it => {
                        return {
                            agent: it
                        };
                    })
                    .sort((a: Assignee, b: Assignee): number => {
                        if (a.agent?.sabre_id === b.agent?.sabre_id) return 0;
                        if (isUndefined(a.agent) || isUndefined(b.agent) || isUndefined(a.agent?.sabre_id)
                            || isUndefined(b.agent?.sabre_id)) return 0;
                        return a.agent?.sabre_id > b.agent?.sabre_id ? 1 : -1;
                    })
            ])
        );
    }

    async searchForPossibleAssignees(query: AssigneesSearchQuery = {}, groupId?: number, ids: AddRemoveInfo = {
        add: [],
        remove: []
    }): Promise<void> {
        this.#assigneesSearchResults.next({
            breadcrumbs: this.#assigneesSearchResults.value?.breadcrumbs ?? [{
                label: tAsString('ROOT'),
                level: AssigneesSearchResultLevel.GLOBAL
            }],
            level: this.#assigneesSearchResults.value?.level ?? AssigneesSearchResultLevel.GLOBAL
        });

        const results = await this.services.groups.searchAssignees(query);
        if(results.breadcrumbs?.last()?.level == AssigneesSearchResultLevel.SEARCH) {
            if (groupId) {
                const currentAssignees: string[] = await this.getAssigneeIds(groupId, ids);

                results!.breadcrumbs.last()!.total_children! = results!.data?.filter(
                    it => !currentAssignees.includes(getAssigneeId(it))
                ).length || 0;
            } else {
                results!.breadcrumbs.last()!.total_children! = results!.data?.filter(
                    it => !ids.add.includes(getAssigneeId(it))
                ).length || 0;
            }
        }

        this.#assigneesSearchResults.next(results);
    }

    async searchConfiguration(groupId: number, cmGroupIds: Array<number>): Promise<void> {
        this.#configurationSearchResults.next(undefined);
        const results = await this.services.groups.searchConfiguration(groupId, cmGroupIds);
        this.#configurationSearchResults.next(results);
    }

    @Bound()
    @Throttled()
    async refreshAllGroups(): Promise<void> {
        await this.getBusyFlag().run(() => this.services.groups.getGroups(), tAsString('REFRESHING_GROUPS'));
    }

    @DataSource()
    getAllGroups$(): Observable<RxGroup[]> {
        return this.db.groups.active$.sort((a: RxGroup, b: RxGroup): number => {
            if (a.name.toUpperCase() === b.name.toUpperCase()) return 0;
            return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
        });
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    getGroupById$(groupId: number): Observable<RxGroup | null> {
        if (isNaN(groupId)) {
            return from([null]);
        }

        return this.db.groups.getById$(asString(groupId));
    }

    @DataSource()
    getGroupsCount$(): Observable<number> {
        return this.getAllGroups$().pipe(
            map(groups => groups.length)
        );
    }

    async createGroup(group: PartialGroup, productStates: ProductState[] = [], propertyStates: PropertyState[] = [], agentsIds: Array<string>): Promise<void> {
        try {
            await this.getBusyFlag().run(async () => {
                await this.services.groups.createGroup(group, productStates, propertyStates, agentsIds);
            }, tAsString('CREATING_GROUP'));
        } finally {
            this.refreshAllGroups().andWeAreDone();
        }
    }

    async updateGroup(group: Group, productStates: ProductState[] = [], propertyStates: PropertyState[] = [], addedCmGroupIds: Array<string>, removedCmGroupIds: Array<string>): Promise<void> {
        try {
            await this.getBusyFlag().run(async () => {
                await this.services.groups.updateGroup(group, productStates, propertyStates, addedCmGroupIds, removedCmGroupIds);
            }, tAsString('UPDATING_GROUP'));
        } finally {
            this.refreshAllGroups().andWeAreDone();
        }
    }

    async deleteGroupsByIds(listIds: number[]): Promise<void> {
        try {
            await this.getBusyFlag().run(() => this.services.groups.deleteGroupsByIds(listIds), tAsString('DELETING_GROUPS'));
        } finally {
            this.refreshAllGroups().andWeAreDone();
        }
    }

    @Bound()
    @Throttled()
    async refreshGroupAssignees(groupId: number): Promise<void> {
        await this.getBusyFlag().run(() => this.services.groups.getAssigneesByGroupId(groupId), tAsString('REFRESHING_GROUP_AGENTS'));
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    getGroupAssignees$(groupId: number): Observable<Assignee[]> {
        return combineLatest([
            this.getGroupAgencies$(groupId),
            this.getGroupAgents$(groupId),
            this.getGroupPccs$(groupId)
        ]).pipe(
            map(([agencies, agents, pccs]) => {
                return [
                    ...agencies.map(it => {
                        return {
                            agency: it.agency_ref
                        };
                    }).filter(it => it.agency),
                    ...pccs.map(it => {
                        return {
                            pcc: it.pcc_ref
                        };
                    }).filter(it => it.pcc),
                    ...agents.map(it => {
                        return {
                            agent: it.agent_ref
                        };
                    }).filter(it => it.agent)
                ];
            })
        );
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    getGroupAgents$(groupId: number): Observable<RxGroupAgent[]> {
        if (isNaN(groupId)) {
            return from([[]]);
        }

        const id = asString(groupId);
        return this.db.group_agents.active$.filter(it => {
            return it.group === id;
        });
    }

    @DataSource()
    getAgencies$(): Observable<RxAgency[]> {
        return this.db.agencies.active$;
    }

    @DataSource()
    getAgents$(): Observable<RxAgent[]> {
        return this.db.agents.active$;
    }

    @DataSource()
    getPccs$(): Observable<RxPcc[]> {
        return this.db.pccs.active$;
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    getGroupAgencies$(groupId: number): Observable<RxGroupAgency[]> {
        if (isNaN(groupId)) {
            return from([[]]);
        }

        const id = asString(groupId);
        return this.db.group_agencies.active$.filter(it => {
            return it.group === id;
        });
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    getGroupPccs$(groupId: number): Observable<RxGroupPcc[]> {
        if (isNaN(groupId)) {
            return from([[]]);
        }

        const id = asString(groupId);
        return this.db.group_pccs.active$.filter(it => {
            return it.group === id;
        });
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    getAgentGroups$(agentId: number): Observable<RxGroupAgent[]> {
        if (isNaN(agentId)) {
            return from([[]]);
        }

        const id = asString(agentId);
        return this.db.group_agents.active$.filter(it => {
            return it.agent === id;
        });
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    getPccGroups$(pccId: number): Observable<RxGroupPcc[]> {
        if (isNaN(pccId)) {
            return from([[]]);
        }

        const id = asString(pccId);
        return this.db.group_pccs.active$.filter(it => {
            return it.pcc === id;
        });
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    getAgencyGroups$(agencyId: number, ): Observable<RxGroupAgency[]> {
        if (isNaN(agencyId)) {
            return from([[]]);
        }

        const id = asString(agencyId);
        return this.db.group_agencies.active$.filter(it => {
            return it.agency === id;
        });
    }

    async searchForProductAssignees(productId: number, groupId: number, ids: AddRemoveInfo = {
        add: [],
        remove: []
    }): Promise<void> {
        const cmGroupIds = (await this.getAssigneeIds$(groupId, ids)).map(asInt);
        return this.services.groups.getProductAssignees(groupId, cmGroupIds, productId);
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    getProductAgents$(productId: number, groupId: number): Observable<RxProductAgent[]> {
        if (isNaN(productId)) {
            return from([[]]);
        }

        const id = asString(productId);
        const group = asString(groupId);
        return this.db.product_agents.active$.filter(it => {
            return (it.product === id && it.groupId === group);
        });
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    getProductPccs$(productId: number, groupId: number): Observable<RxProductPcc[]> {
        if (isNaN(productId)) {
            return from([[]]);
        }

        const id = asString(productId);
        const group = asString(groupId);
        return this.db.product_pccs.active$.filter(it => {
            return (it.product === id && it.groupId === group);
        });
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    getProductAgencies$(productId: number, groupId: number): Observable<RxProductAgency[]> {
        if (isNaN(productId)) {
            return from([[]]);
        }

        const id = asString(productId);
        const group = asString(groupId);
        return this.db.product_agencies.active$.filter(it => {
            return (it.product === id && it.groupId === group);
        });
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    getProductAssignees$(productId: number, groupId: number): Observable<Assignee[]> {
        return combineLatest([
            this.getProductAgencies$(productId, groupId),
            this.getProductPccs$(productId, groupId),
            this.getProductAgents$(productId, groupId)
        ]).pipe(
            map(([agencies, pccs, agents]) => {
                return [
                    ...agencies.map(it => {
                        return {
                            agency: it.agency_ref
                        };
                    }).filter(it => it.agency),
                    ...pccs.map(it => {
                        return {
                            pcc: it.pcc_ref
                        };
                    }).filter(it => it.pcc),
                    ...agents.map(it => {
                        return {
                            agent: it.agent_ref
                        };
                    }).filter(it => it.agent)
                ];
            })
        );
    }
}
