import HashWizard, {HashWizardProps} from '../../../../common/components/wizards/HashWizard';
import {GroupWizardSteps} from './GroupWizardSteps';
import GroupWizardDetailsStepPresenter from './details-step/GroupWizardDetailsStepPresenter';
import {useControllers} from '../../../../../../common/helpers/react/hooks/useControllers';
import GroupWizardSettingsStepPresenter from './settings-step/GroupWizardSettingsStepPresenter';
import {useParams} from 'react-router-dom';
import {asNumber} from '../../../../../../common/helpers/converters/asNumber';
import {asJsonObject} from '../../../../../../common/helpers/converters/asJsonObject';
import {ensure} from '../../../../../../common/types/guards/ensure';
import {map} from 'rxjs';
import {tAsString} from '../../../../../../common/helpers/react/text/tAsString';
import GroupWizardLoadingPlaceholder from './GroupWizardLoadingPlaceholder';
import {
    HashWizardStepDescription
} from '../../../../common/components/wizards/HashWizardStepDescription';
import {isString} from '../../../../../../common/types/guards/isString';
import {isPartialRxGroup} from '../../../../../../common/model/db/types/RxGroup';
import {isProductState} from '../../../../../../common/model/types/products/isProductState';
import {isRecordOf} from '../../../../../../common/types/guards/isRecordOf';
import {isArrayOf} from '../../../../../../common/types/guards/isArrayOf';
import GroupWizardAgentsStepPresenter from './agents-step/GroupWizardAgentsStepPresenter';
import {isPlainObject} from '../../../../../../common/types/guards/isPlainObject';
import {isPropertyState} from '../../../../../../common/model/types/properties/isPropertyState';
import {asInt} from '../../../../../../common/helpers/converters/asInt';
import {useHashWizard} from '../../../../common/components/wizards/useHashWizard';
import React from 'react';
import GoToDefaultPage from '../../../../common/goto/GoToDefaultPage';
import {getYupValidationSchema} from './details-step/GroupWizardDetailsStepForm';
import {useOnce} from '../../../../../../common/helpers/react/hooks/useOnce';

export default function GroupWizardPresenter() {
    const {id} = useParams();

    const wh = useHashWizard();

    const controllers = useControllers();
    const configurations = controllers.configurations;
    const groups = controllers.groups;
    const redApps = controllers.redApps;
    const dialog = controllers.dialog;

    useOnce(() => redApps.refreshRedApps()).andWeAreDone();

    const groupId = asNumber(id);
    const group = groups.getGroupById$(groupId).useState();

    if (!isNaN(groupId) && group === null) {
        return <GoToDefaultPage/>;
    }

    const steps: HashWizardStepDescription[] = [
        {
            name: GroupWizardSteps.DETAILS,
            label: 'DEFINE_GROUP_STEP',
            element: <GroupWizardDetailsStepPresenter/>,
            onValidate: async (data) => {
                await getYupValidationSchema().validate(data);
            },
            getDefaultData: async () => {
                // NOTE: take the most recent data always
                return groups
                    .getGroupById$(groupId)
                    .pipe(
                        map(it => asJsonObject(it))
                    );
            }
        },
        {
            name: GroupWizardSteps.AGENTS,
            label: 'ASSIGNEES_STEP',
            element: <GroupWizardAgentsStepPresenter/>,
            onBeforeLoaded: async () => groups.refreshGroupAssignees(groupId)
        },
        {
            name: GroupWizardSteps.SETTINGS,
            label: 'SETTINGS_STEP',
            element: <GroupWizardSettingsStepPresenter/>,
            onBeforeLoaded: async () => {
                await groups.refreshGroupAssignees(groupId);
                await configurations.refreshConfigurations();

                const allData = await wh.allStepsData$;

                const toAdd = allData?.data?.[GroupWizardSteps.AGENTS]?.add ?? [];
                ensure(isArrayOf(isString), toAdd);

                const toRemove = allData?.data?.[GroupWizardSteps.AGENTS]?.remove ?? [];
                ensure(isArrayOf(isString), toRemove);

                const ids = await groups.getAssigneeIds(groupId, {
                    add: toAdd,
                    remove: toRemove
                });

                await groups.searchConfiguration(groupId, ids.map(asInt));
            }
        }
    ];

    const onSubmit: HashWizardProps['onSubmit'] = function (data, wizardHelpers) {
        const detailsStepData = data[GroupWizardSteps.DETAILS];
        ensure(isPartialRxGroup, detailsStepData);

        const agentsToAddData = data[GroupWizardSteps.AGENTS]?.add ?? [];
        ensure(isArrayOf(isString), agentsToAddData);

        const agentsToRemoveData = data[GroupWizardSteps.AGENTS]?.remove ?? [];
        ensure(isArrayOf(isString), agentsToRemoveData);

        const settingsStepData = data[GroupWizardSteps.SETTINGS] ?? {};
        ensure(isPlainObject, settingsStepData);

        const productsData = settingsStepData['products'] ?? {};
        ensure(isRecordOf(isProductState), productsData);

        const propertiesData = settingsStepData['properties'] ?? {};
        ensure(isRecordOf(isPropertyState), propertiesData);

        const propertyStates = Object.values(propertiesData);
        ensure(isArrayOf(isPropertyState), propertyStates);

        const successMessage = wizardHelpers.isEditing
            ? 'THE_X_GROUP_HAS_BEEN_UPDATED'
            : 'THE_X_GROUP_HAS_BEEN_CREATED';

        const errorMessage = wizardHelpers.isEditing
            ? 'THE_X_GROUP_UPDATE_FAILED'
            : 'THE_X_GROUP_CREATION_FAILED';

        return dialog.takeNotifiedAction({
            action: async () => {
                console.debug(`Wizard data: `, data);

                if (wizardHelpers.isEditing) {
                    ensure(isString, detailsStepData.name);

                    const group = {
                        id: groupId,
                        name: detailsStepData.name,
                        description: detailsStepData.description
                    };

                    const productStates = Object.values(productsData);
                    ensure(isArrayOf(isProductState), productStates);

                    await groups.updateGroup(group, productStates, propertyStates, agentsToAddData, agentsToRemoveData);
                } else {
                    const partialGroup = {
                        name: detailsStepData.name,
                        description: detailsStepData.description
                    };

                    const productStates = Object.values(productsData);
                    ensure(isArrayOf(isProductState), productStates);

                    await groups.createGroup(partialGroup, productStates, propertyStates, agentsToAddData);
                }

                await wizardHelpers.destroyWizard();
            },
            successMessage: tAsString(successMessage, {
                name: detailsStepData.name
            }),
            errorMessage: tAsString(errorMessage, {
                name: detailsStepData.name
            }),
            finalizer: async () => {
                await groups.refreshAllGroups();
            }
        });
    };

    return <HashWizard
        steps={steps}
        cancelPage={'groups'}
        fallback={<GroupWizardLoadingPlaceholder/>}
        onSubmit={onSubmit}
        onBeforeLoaded={async (data, wizardHelpers) => {
            if (wizardHelpers.isEditing) {
                await groups.refreshAllGroups();
            }
        }}
    />;

}