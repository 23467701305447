import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import {useConfigurationsTableColumns} from './useConfigurationsTableColumns';
import DefaultTable from '../../../common/components/table/DefaultTable';
import {Assignee} from "../../../../../common/model/types/assignees/Assignee";
import {RxConfiguration} from "../../../../../common/model/db/types/RxConfiguration";

export const ConfigurationsTable = () => {
    const configurations = useControllers().configurations;
    const redApps = useControllers().redApps;
    const columns = useConfigurationsTableColumns();
    const redappsData = redApps.getRedApps$().useState() ?? [];
    const configurationsData = configurations.getConfigurations$().useState() ?? [];
    const data = configurationsData.map(configuration => ({
        ...configuration,
        redapp_name: redappsData.find(redapp => redapp.iu_name === configuration.redapp_name)?.name ?? configuration.redapp_name
    }))
    .sort((a: RxConfiguration, b: RxConfiguration): number => {
        if (a.redapp_name.toUpperCase() === b.redapp_name.toUpperCase()) return 0;
        return a.redapp_name.toUpperCase() > b.redapp_name.toUpperCase() ? 1 : -1;
    });
    const isBusy = configurations.isBusy$().useState() !== false;

    return <DefaultTable
        columns={columns}
        data={data}
        isBusy={isBusy}
    />;
};